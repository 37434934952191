
import { useEffect, useState } from "react";

const asyncMod = async () => {

  return import(`../src/modules/${process.env.REACT_APP_MOD}/index.tsx`);

}

export default () => {


  const [mod,setMod] = useState<any>(null);
  
  useEffect(()=>{

    asyncMod().then(ret=>setMod(ret.default));

  },[]);
 
  return (
    <>
      {mod}
    </>
  )

};
